
@media (min-width: 1200px) {
  .container{width:1140px;}
    .bg-main div.bg-prd-slider{padding-bottom:130px;}
    .auction-left{width:760px; float:left;}
    .auction-right{float:right;width:370px; margin-right: 20px;}
  /*kk*/
  .static-search-form{padding-top:15px; padding-bottom:15px;  }
  .static-header-logo{padding-top:15px; padding-bottom:15px; }
  .header-bottom .col-lg-7 {  width: 100% !important;}
  /*kk*/
}

@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-right{float:left;}
    .btn-add-auction{margin:0 0 0 0;}
    .auction-left{width:100%!important; float:none;}
    .auction-right{width:100%!important; float:none; margin-top:15px;}
    .carousel-indicators, bg-thumb-navi{display:none;}
  /*Index*/
  /*22-12-2016*/
  .bg-nav {height:50px;}
  .navbar {position:inherit; min-height:50px; margin-bottom:20px;}
  .navbar-default ul li a {font-size:0.9em;}
  .navbar-default ul li a {text-transform:uppercase; line-height:50px; padding:0 14px;}
  /*22-12-2016 end*/
  
  .btn-add-auction {margin-top:0; background-image:url(../img/btn-add-auction.png); background-position:10px center; line-height:48px!important;}
  #myCarousel .carousel-caption {top:50px; left:85px; bottom:0; text-align:left; padding:0;} 
  #myCarousel .carousel-caption h4 {font-size:53px;}
  #myCarousel .carousel-caption h5 {font-size:27px;}
  #myCarousel .carousel-caption p {font-size:18px;}
  #owl-categories .owl-item div.box a.btn {width:140px; font-size:16px;}
  #owl-categories .owl-item div.box a.btn {font-size:16px; width:140px; left:45px; top:68px;}
  .btn-featured {margin-top:15px;} 
  .box-content {width:62%; padding:2%; padding-bottom:0;}
  .box-content .header {padding:0 0 2% 0;}
  .box-content .detail-data {padding: 2% 0 0 0;}
  #owl-twitter strong {margin-left:10px;}
  #owl-twitter a {margin-left:10px;}
  
  /*dedicated_Auction*/
  .bg-main h2 {text-align:center;}
  #prdCarousel div.item img {width:100%;}
  .bg-thumb-navi h5 {font-size:22px;}
  .bg-bid-now {padding:20px 0; width:50%;}
  
  /*Settings-Dashboard*/
  .note-grey {clear:both;}
  
  /*All Auction*/
  .all-auction .box .img-responsive {height:160px;}
  .all-auction .box div.link-box {padding:10px;}
  .all-auction .box div.auction-desc table td strong {font-size:20px;}
  .all-auction .list-veiw .pjct-img {width:37%;}
  .all-auction .list-veiw .box .img-responsive {height:inherit;}
  .shortBy-bg {padding:9px 0; margin:6px 0 0 0;}
  
  /*kk*/
  .static-search-form{padding-top:15px; padding-bottom:15px;  margin-right: 8%;}
  .static-header-logo{padding-top:15px; padding-bottom:15px; margin-left:8%;}
  .header-bottom .col-lg-7 {  width: 100% !important;}
  
  .blog-list{width:620px;}
  /*kk*/
  
}

@media (min-width: 768px) and (max-width: 991px) {
    .navbar-right{float:left;}
    .btn-add-auction{margin:10px;}
    .auction-left{width:100%!important; float:none;}
    .auction-right{width:100%!important; float:none; margin-top:15px;}
    .carousel-indicators, bg-thumb-navi{display:none;}
  /*Index*/
  /*22-12-2016*/
  .bg-nav {height:50px;}
  .navbar {position:inherit; min-height:50px; margin-bottom:20px;}
  .navbar-default ul li a {font-size:0.8em;}
  .navbar-default ul li a {text-transform:uppercase; line-height:50px; padding:0 5.3%;}
  .btn-add-auction {margin-top:0; background-image:url(../img/btn-add-auction.png); background-position:10px center; line-height:48px!important;}
  /*22-12-2016 end*/
  #owl-categories .owl-item div.box {margin-right:20px; position:relative;} 
  .img-responsive {width:100%; height:100%;}  
  .list-veiw .pjct-img {float:left; max-width:31%; max-height:100%; background:#e7eaee; padding:0;}
  .box-content .detail-data .left {float:left; width:100%;}
  .box-content .left h2 {float:left; width:100%; color:#4477bd; font-size:20px; margin:0;}
  .box-content .right .price {float:right; color:#404040; font-size:20px;}
  .box-content .left img {margin-top:2%;}
  .box-content .right .bids-count {margin-top:1%;}
  .box-content .header {padding:0 0 6px 0;}
  .box-content .detail-data {padding:10px 0 0 0;}
  .box-content .detail-data p {font-size:13px;}
  .bg-more-featured-auction .box div.auction-desc p {font-size:11px;}
  .box-content .auction-desc {float:left; width:100%;}
  .box-content .detail-data .right {float:left; width:100%; border:1px solid #dddfe3; border-bottom:none; margin-top:10px;} 
  #myCarousel .carousel-caption {top:30px; left:40px; bottom:0; text-align:left; padding:0;} 
  #myCarousel .carousel-caption h4 {font-size:53px;}
  #myCarousel .carousel-caption h5 {font-size:22px;}
  #myCarousel .carousel-caption p {font-size:18px;}
  #owl-categories .owl-item div.box a.btn {font-size:14px; width:130px; left:21px; top:53px;}
  
  .btn-featured {margin-top:15px;} 
  .btn-featured {font-size:18px; padding:10px 25px; margin-right:35px; margin-top:35px; display:inline-block;}
  .bg-footer {background-color:#4477bd; padding-top:3%;}
  .cat-rspnv {width:50%; float:left;}
  .twit-usr {width:auto;}
  .twit-brd {border-top:1px solid #5c90d6; padding:2%; clear:both;}
  
  /*kk*/ 
  .static-header-menu{ width:100% !important;}
  .col-md-offset-1 {  margin-left: 0% !important; float:left;}
  .header-bottom .col-lg-7 {  width: 100% !important;}
  /*kk*/ 
  
  /*Settings-Dashboard*/
  .right-panel h2 {font-size:22px;}
  .pleaseNote p {line-height:20px;}
  .head-note {width:96.1%;}
  .field-group-paypal .left-label {width:27%;}
  .field-group-paypal .right-control {width:73%;}
  .note-grey {clear:both;}
  
  /*All Auction*/
}

@media (max-width: 767px) {
    .navbar-right{float:left;}
    .btn-add-auction{margin:10px;}
    .auction-left{width:100%!important; float:none; padding:0 2%;}
    .auction-right{width:100%!important; float:none; margin-top:15px; padding:0 2%;}
    .carousel-indicators, bg-thumb-navi{display:none;}
  /*Index*/
  .bg-top-menu {padding:0 2%;}
  //header {padding:2%;}
  .bg-custom-banner {padding:2%;} 
  .bg-categories {background-color:#fff; padding:2%;}
  .bg-categories h4 {padding:0 0 0 0;}
  .featured-Navigation {float:right; padding-bottom:15px; padding-right:0;}
  #owl-categories .owl-item:first-child { margin-left:0!important;} 
  #owl-categories .owl-item div.box {margin-right:20px; position:relative;} 
  .img-responsive {width:100%; height:100%;}  
  .list-veiw .pjct-img {float:left; max-width:31%; max-height:100%; background:#e7eaee; padding:0;}
  .box-content .detail-data .left {float:left; width:100%;}
  .box-content .left h2 {float:left; width:100%; color:#4477bd; font-size:20px; margin:0;}
  .box-content .right .price {float:right; color:#404040; font-size:20px;}
  .box-content .left img {margin-top:2%;}
  .box-content .right .bids-count {margin-top:1%;}
  .box-content .header {padding:0 0 6px 0;}
  .box-content .detail-data {padding:10px 0 0 0;}
  .box-content .detail-data p {font-size:13px; text-align:center;}
  .bg-more-featured-auction .box div.auction-desc p {font-size:11px;}
  .box-content .auction-desc {float:left; width:100%;}
  .box-content .detail-data .right {float:left; width:100%; border:1px solid #dddfe3; border-bottom:none; margin-top:10px;}
  .bg-custom-banner {padding-top:25px; padding-bottom:60px; background-color:#fff;} 
  .bg-featured {padding:2%;}
  #owl-featured .owl-item div.box {margin-right:0;}
  #owl-featured .owl-item {margin-left:0; width:10%; padding:0.2%;} 
  .bg-more-featured-auction {padding-bottom:50px; padding:3%;}
  .bg-more-featured-auction .nav-tabs {margin-right:0; float:left !important;}
  .owl-wrapper-outer .owl-item .featured-img {width:100%; height:100%;}
  #owl-categories .owl-item {margin:0;}
  #owl-categories .owl-item div.box {margin:0; padding:2%;}
  #owl-categories .owl-item div.box a.btn {font-size:18px; width:190px; left:17%; top:44%;}
  .bg-footer {background-color:#4477bd; padding-top:3%;}
  
  
  /*kk*/ 
  .static-area-header-top .static-search-form{padding-top:0px; padding-bottom:15px; padding-right: 20px; margin-bottom:8px; margin-right: 0%;}
  .static-header-logo{padding-top:15px; }
  .static-header-custom .bg-top-menu .container{padding-left:0px; padding-right:0px; }
  #header .search-form{ margin-top:0px;}
  .search-form{margin-top:0px;}
  
  
  /*#menu-primary{ margin-top:0px;}
  .menu-primary{ margin-top:0px; padding:0px;}
  
  #menu-primary .menu-primary_trigger {display:none;}
  .menu-items{ z-index:5px;}*/
  
  /*.simple-menu.menu-items {  background: #454545 none repeat scroll 0 0;  padding: 0px !important;}*/
  /*kk*/
  
  /*dedicated_Auction*/
  .bg-breadcrumb {display:none;}
  .bg-main h2 {text-align:center; padding:0 2%;}
  .bg-auction-button {padding:2% 0;}
  .border-bottom {padding:3% 0 2% 0; border:none; border-top:1px solid #d5d9df;}
  .bg-bid-now p {line-height:14px;}
  .prod-count {float:left; width:50%;}
  .buy-btn-rspnv {float:left; width:50%;}
  .btn-buy-now {font-size:16px; padding:10px 50px; text-align:center;}
  
  .bg-footer {background-color:#4477bd; padding-top:3%;}
  .cat-rspnv {width:50%; float:left;}
  .twit-usr {width:auto;}
  .twit-brd {float:left; width:100%; border-top:1px solid #5c90d6; padding:2%; margin-top:3%; clear:both;}
  
  /*Settings-Dashboard*/
  .bg-main {padding:1.4%;}
  .left-sidebar {width:100%; display:none;}
  .sidebar-bg:last-child {margin:0;}
  .note-grey {clear:both;}
  
  /*All Auction*/
  .left-sidebar {display:block;}
  .sidebar-bg {width:100%;}
  .sidebar-menu form {padding:3%;}
  .sidebar-menu form .field {margin-bottom:2%;}
  .sidebar-menu form .shortfield {margin:0 2% 2% 0;}
  .sidebar-menu form .shortfield:nth-child(3) {margin-right:0;}
  .sidebar-menu form .shortfield {width:49%;}
  .sidebar-bg {width:100%; margin:0 2% 2% 0;}
  .sidebar-bg .sidebar-menu > li > a {padding:2%;}
  .sidebar-menu > li > a {padding:1.5% 5% 1.5% 5%;}
  .right-panel {width:100%;}
  .all-auction .text-right {margin:9px 40% 20px 0;}
  .all-auction .box .img-responsive {height:430px;}
  //.all-auction .pagination {margin:1% 25% 3% 0;}
  .all-auction .list-veiw .pjct-img {height:187px;}
  .all-auction .box .img-responsive {height:100%;}
}

@media (max-width: 480px) {
    .navbar-right{float:left;}
    .btn-add-auction{margin:10px;}
    .auction-left{width:100%!important; float:none;}
    .auction-right{width:100%!important; float:none; margin-top:15px;}
    .carousel-indicators, bg-thumb-navi{display:none;}
  /*Index*/
  .bg-top-menu {padding:0 3% 0 3%;}
  //header {padding:3%;}
  .txtSearch {float:left; width:100%; background-position:97% 50%;} 
  .bg-categories {padding:3% 3% 6% 3%;} 
  .bg-custom-banner {padding-top:15px; padding-bottom:30px; background-color:#fff;}
  .loginmodal-container {max-width:90%;}
  .loginmodal-container h1 {font-size:1.4em; margin:10px 0 0 0;}
  .loginmodal-container input[type=text], input[type=password] {height:38px;}
  .bg-featured {padding:3%;}
  .bg-featured h4 {width:50%; font-size:22px;}
  .featured-Navigation {float:right;}
  #owl-featured .owl-item {margin-left:0;}
  #owl-featured .owl-item div.box {margin:2%;}
  .featured-img {width:100%; height:100%;}
  #owl-featured .owl-item div.box a.link {font-size:16px; color:#4477bd; margin:5% 4% 2% 4%; display:inline-block;}
  #owl-featured .owl-item div.box p {font-size:13px; text-align:left; color:#606060; margin:5px 5px 25px 5px;}
  .bg-categories h4 {width:50%; font-size:22px; text-align:left;}
  #owl-categories .owl-item {margin:0;}
  #owl-categories .owl-item div.box {margin:0;}
  #owl-categories .owl-item div.box img {width:100%;}
  #owl-categories .owl-item div.box a.btn {font-size:18px; width:190px; left:17%; top:45%;}
  .bg-advertise {padding:10% 0 0 0; height:120px;}
  .bg-more-featured-auction {padding:3%;}
  .bg-more-featured-auction .nav-tabs {margin-top:15px; padding:0px 154px 0 0;}
  //.bg-more-featured-auction h4, p {text-align:center;}
  
  /*21-12-2016*/
  
  /*About*/
  .about-content h2, h3 {text-align:center;}
  
  .blog-list {padding:0 10px;}
  .blog-list {width:100%;}
  .blog {padding:10px;}
  .blog-desc {padding:0;}
  .blog-desc p {text-align:left;}
  .bg-more h3 {text-align:left;}
  
  /*21-12-2016*/
  
  /*list*/
  .list-veiw .pjct-img {float:none; max-width:100%; height:100%; padding:0;}
  .box-content {float:right; width:100%; padding:2.4%; padding-bottom:0;}
  .box-content .detail-data .right {border:1px solid #dddfe3; border-left:none; border-bottom:none; border-right:none; margin-top:10px;}
  .bg-more-featured-auction .box div.auction-desc p {margin-top:10px;}
  .bg-more-featured-auction .btn-show-auction {margin-bottom:10px;}
  .bg-footer {background-color:#4477bd; padding-top:3%;}
  
  /*kk*/ 
  .static-area-header-top .static-search-form{padding-top:0px; padding-bottom:15px; padding-right: 20px; margin-bottom:8px; margin-right: 0%;}
  .static-header-logo{padding-top:15px; }
  .static-header-custom .bg-top-menu .container{padding-left:0px; padding-right:0px; }
  #header .search-form{ margin-top:0px;}
  .search-form{margin-top:0px;}
  
  #menu-primary{ margin-top:0px;}
  .menu-primary{ margin-top:0px; padding:0px;}
  
  #menu-primary .menu-primary_trigger {display:none;}
  .menu-items{ z-index:5px;}
  
  .list-veiw .pjct-img {  width: 100% !important;}
  
  .col-md-offset-1 {  margin-left: 0% !important;}
  .simple-menu.menu-items {  background: #454545 none repeat scroll 0 0;  padding: 0px !important;}
  /*kk*/
  
  /*dedicated_Auction*/
  .bg-breadcrumb {display:none;}
  .bg-main h2 {text-align:center; padding:0 2%;}
  .bg-auction-button {padding:2% 0;}
  .btn-auction-tab {width:48%; text-align:center; margin-bottom:3%;}
  .bg-box p {text-align:left; margin-bottom:0;}
  .border-bottom {padding:3% 0 2% 0; border:none; border-top:1px solid #d5d9df;}
  .bg-bid-now p {line-height:14px;}
  .prod-count {float:left; width:50%;}
  .buy-btn-rspnv {float:left; width:50%;}
  .btn-buy-now {font-size:16px; padding:10px 0; text-align:center;}
  .bg-bid-now p {text-align:left;}
  
  .bg-footer {background-color:#4477bd; padding-top:3%;}
  .bg-footer p {text-align:left;}
  .cat-rspnv {width:50%; float:left;}
  .twit-usr {width:auto;}
  .twit-brd {float:left; width:100%; border-top:1px solid #5c90d6; padding:2%; margin-top:3%; clear:both;}
  footer p, a {text-align:left;}
  
  /*All Auction*/
  .shortBy-bg ul li:first-child {width:100%; text-align:center; padding-bottom:10px; border-bottom:1px solid #dddfe3;}
  .shortBy-bg ul li {width:50%; border-right:0;}
  .shortBy-bg ul li a {line-height:30px;}
  .all-auction .list-veiw .pjct-img {width:100%; height:290px;}
  //.all-auction .pagination {margin:1% 15% 3% 0;}
  .all-auction .text-right {margin:9px 33% 20px 0;}
  
  /*21-12-2016*/
  
  /*wiretransfer*/
  .payment-tab {float:left; width:100%; background:#f3f4f7; border-top:1px solid #d2d5db; border-bottom:1px solid #d2d5db;}
  .payment-tab ul {list-style:none; padding:0;}
  .payment-tab ul li {float:left; width:50%; font-size:16px; border-right:1px solid #d2d5db;}
  .payment-tab ul li a {float:left; width:100%; color:#404040; padding:0 20px; line-height:44px;}
  
  .field-group-paypal .left-label {float:left; width:auto;}
  .field-group-paypal .right-control {width:auto;}
  
  /*User-Profile*/
  .user-profile .heading {margin-top:10px;}
  .user-profile .country {margin:0;}
  .user-profile .detail p {text-align:left;}
  
  /*Dashboard*/
  .field-group-paypal .left-label {width:100%;}
  .field-group-paypal .right-control {width:100%;}
  
  .left-label-long {width:100%;}
  .short-right-control {width:100%;}
  
  /*21-12-2016*/
}

@media (max-width: 360px) {
    .navbar-right{float:left;}
    .btn-add-auction{margin:10px;}
    .auction-left{width:100%!important; float:none;}
    .auction-right{width:100%!important; float:none; margin-top:15px;}
    .carousel-indicators, bg-thumb-navi{display:none;}
  
  /*Index*/
  #myCarousel .carousel-caption {top:20px; left:25px; bottom:0px; right:0; text-align:left; padding:0;}
  #myCarousel .carousel-caption h4 {margin-top:0; font-size:14px;}
  .featured-Navigation {float:left;}
  .bg-more-featured-auction .nav-tabs {margin-top:15px; padding:0px 72px 0 0;}
  .box-content .left h2 {width:100%; font-size:16px; margin:0;}
  .box-content .right .price {font-size:16px;}
  .box-content .right .bids {font-size:14px;}
  .box-content .right .bids img {margin:-3px 2px 0 4px;}
  .box-content .right .bids {margin:0;}
  .box-content .right .bin {font-size:14px;}
  .btn-group-justified .btn {font-size:16px; padding:6px 10px;} 
  .bg-footer {background-color:#4477bd; padding-top:3%;}
  
  /*kk*/ 
  .static-area-header-top .static-search-form{padding-top:0px; padding-bottom:15px; padding-right: 20px; margin-bottom:8px; margin-right: 0%;}
  .static-header-logo{padding-top:15px; }
  .static-header-custom .bg-top-menu .container{padding-left:0px; padding-right:0px; }
  #header .search-form{ margin-top:0px;}
  .search-form{margin-top:0px;}
  
  #menu-primary{ margin-top:0px;}
  .menu-primary{ margin-top:0px; padding:0px;}
  
  #menu-primary .menu-primary_trigger {display:none;}
  .menu-items{ z-index:5px;}
  
  .list-veiw .pjct-img {  width: 100% !important;}
  .col-md-offset-1 {  margin-left: 0% !important;}
  .simple-menu.menu-items {  background: #454545 none repeat scroll 0 0;  padding: 0px !important;}
  
  .register-form .head-note {margin:0px;}
  /*kk*/
  
  /*dedicated_Auction*/
  .auction-left {padding:0 3%;}
  .btn-auction-tab {font-size:14px;}
  .bg-box div.bg-comment {padding:5px 10px 12px 10px; margin:10px 0 10px 0;}
  .bg-box .media .media-body p {line-height:20px;}
  .bg-box div.bg-comment h4 {font-size:18px; margin:10px 0 10px 0;}
  .auction-right {padding:0 3%;}
  .table-bid-now {font-size:16px;}
  .table-bid-now td strong {font-size:30px;}
  .bg-box div.bg-comment .form-control {padding:3% 3%; font-size:14px;}
  .btn-submit {padding:0 7%; line-height:32px;}
  .bg-bid-now .form-control {padding:3%; font-size:14px;}
  .prod-count {width:100%; margin-bottom:3%;}
  .buy-btn-rspnv {width:100%;}
  .border-bottom {padding:3% 0 6% 0;}
  footer p, a {color:;}
  .cat-rspnv:first-child {border:none;} 
  .cat-rspnv {width:100%; border-top:1px solid #5c90d6; padding:2%; margin-top:3%;}
   
   /*All Auction*/
  .head-note {margin:5% 2%;}
  .all-auction .text-right {margin:9px 24% 20px 0;}
  .all-auction .list-veiw .pjct-img {height:190px;}
  .box-content .left {width:100%;}
  .box-content .left img {display:none;}
  .box-content .right {width:100%;}
  .box-content .left h2 { text-align:left;}
  .box-content .right .price {float:left;}
  .box-content .right .price span { text-align:left;width:100%;}
  
  .blog-sidebar { padding-left:20px;}
  
}

/*
Description: The stylesheet with a responsive grid.
Author:      Cherry Team
Version:     1.0.0
*/
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}



@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-2 {
    width: 16.66667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.33333%;
  }

  .col-sm-5 {
    width: 41.66667%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.33333%;
  }

  .col-sm-8 {
    width: 66.66667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.33333%;
  }

  .col-sm-11 {
    width: 91.66667%;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-pull-1 {
    right: 8.33333%;
  }

  .col-sm-pull-2 {
    right: 16.66667%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-4 {
    right: 33.33333%;
  }

  .col-sm-pull-5 {
    right: 41.66667%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-7 {
    right: 58.33333%;
  }

  .col-sm-pull-8 {
    right: 66.66667%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-10 {
    right: 83.33333%;
  }

  .col-sm-pull-11 {
    right: 91.66667%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-push-1 {
    left: 8.33333%;
  }

  .col-sm-push-2 {
    left: 16.66667%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-4 {
    left: 33.33333%;
  }

  .col-sm-push-5 {
    left: 41.66667%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-7 {
    left: 58.33333%;
  }

  .col-sm-push-8 {
    left: 66.66667%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-10 {
    left: 83.33333%;
  }

  .col-sm-push-11 {
    left: 91.66667%;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-2 {
    width: 16.66667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.33333%;
  }

  .col-md-5 {
    width: 41.66667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.33333%;
  }

  .col-md-8 {
    width: 66.66667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.33333%;
  }

  .col-md-11 {
    width: 91.66667%;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-pull-1 {
    right: 8.33333%;
  }

  .col-md-pull-2 {
    right: 16.66667%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-4 {
    right: 33.33333%;
  }

  .col-md-pull-5 {
    right: 41.66667%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-7 {
    right: 58.33333%;
  }

  .col-md-pull-8 {
    right: 66.66667%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-10 {
    right: 83.33333%;
  }

  .col-md-pull-11 {
    right: 91.66667%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-push-1 {
    left: 8.33333%;
  }

  .col-md-push-2 {
    left: 16.66667%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-4 {
    left: 33.33333%;
  }

  .col-md-push-5 {
    left: 41.66667%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-7 {
    left: 58.33333%;
  }

  .col-md-push-8 {
    left: 66.66667%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-10 {
    left: 83.33333%;
  }

  .col-md-push-11 {
    left: 91.66667%;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .col-md-offset-2 {
    margin-left: 16.66667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333%;
  }

  .col-md-offset-5 {
    margin-left: 41.66667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333%;
  }

  .col-md-offset-8 {
    margin-left: 66.66667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333%;
  }

  .col-md-offset-11 {
    margin-left: 91.66667%;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-2 {
    width: 16.66667%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.33333%;
  }

  .col-lg-5 {
    width: 41.66667%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.33333%;
  }

  .col-lg-8 {
    width: 66.66667%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.33333%;
  }

  .col-lg-11 {
    width: 91.66667%;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-pull-1 {
    right: 8.33333%;
  }

  .col-lg-pull-2 {
    right: 16.66667%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-4 {
    right: 33.33333%;
  }

  .col-lg-pull-5 {
    right: 41.66667%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-7 {
    right: 58.33333%;
  }

  .col-lg-pull-8 {
    right: 66.66667%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-10 {
    right: 83.33333%;
  }

  .col-lg-pull-11 {
    right: 91.66667%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-push-1 {
    left: 8.33333%;
  }

  .col-lg-push-2 {
    left: 16.66667%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-4 {
    left: 33.33333%;
  }

  .col-lg-push-5 {
    left: 41.66667%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-7 {
    left: 58.33333%;
  }

  .col-lg-push-8 {
    left: 66.66667%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-10 {
    left: 83.33333%;
  }

  .col-lg-push-11 {
    left: 91.66667%;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }
}
/*
Description: The stylesheet with a main responsive style.
Author:      Cherry Team
Version:     1.0.0
*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    line-height: 34px;
  }

  input[type="date"].input-sm,
  input[type="time"].input-sm,
  input[type="datetime-local"].input-sm,
  input[type="month"].input-sm {
    line-height: 30px;
  }

  input[type="date"].input-lg,
  input[type="time"].input-lg,
  input[type="datetime-local"].input-lg,
  input[type="month"].input-lg {
    line-height: 46px;
  }
}
@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-static {
    display: inline-block;
  }
  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn,
  .form-inline .input-group .form-control {
    width: auto;
  }
  .form-inline .input-group > .form-control {
    width: 100%;
  }
  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio,
  .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }
  .form-inline .radio label,
  .form-inline .checkbox label {
    padding-left: 0;
  }
  .form-inline .radio input[type="radio"],
  .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 14.3px;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
  }
}

@media (max-width: 768px) {
  ol.comment-list li.comment ol.children {
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  .table-responsive {
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive > .table {
    margin-bottom: 0;
  }
  .table-responsive > .table > thead > tr > th,
  .table-responsive > .table > thead > tr > td,
  .table-responsive > .table > tbody > tr > th,
  .table-responsive > .table > tbody > tr > td,
  .table-responsive > .table > tfoot > tr > th,
  .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap;
  }
  .table-responsive > .table-bordered {
    border: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:first-child,
  .table-responsive > .table-bordered > thead > tr > td:first-child,
  .table-responsive > .table-bordered > tbody > tr > th:first-child,
  .table-responsive > .table-bordered > tbody > tr > td:first-child,
  .table-responsive > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .table-responsive > .table-bordered > thead > tr > th:last-child,
  .table-responsive > .table-bordered > thead > tr > td:last-child,
  .table-responsive > .table-bordered > tbody > tr > th:last-child,
  .table-responsive > .table-bordered > tbody > tr > td:last-child,
  .table-responsive > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .table-responsive > .table-bordered > tbody > tr:last-child > th,
  .table-responsive > .table-bordered > tbody > tr:last-child > td,
  .table-responsive > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0;
  }
}

@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .dl-horizontal dd {
    margin-left: 180px;
  }
}

@-ms-viewport {
  width: device-width;
}
.visible-xs, .visible-sm, .visible-md, .visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }

  table.visible-sm {
    display: table;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table;
  }

  tr.visible-md {
    display: table-row !important;
  }

  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table;
  }

  tr.visible-print {
    display: table-row !important;
  }

  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .site-header.boxed,
  .site-content.boxed,
  .site-footer.boxed {
    max-width: 750px;
	background-color:#f3f4f7;/*yt*/
  }
}
@media (min-width: 992px) {
  .site-header.boxed,
  .site-content.boxed,
  .site-footer.boxed {
    max-width: 970px;
	background-color:#f3f4f7;/*yt*/
  }
}
@media (min-width: 1200px) {
  .site-header.boxed,
  .site-content.boxed,
  .site-footer.boxed {
    max-width: 1170px;
	background-color:#f3f4f7;/*yt*/
  }
}
@media (min-width: 768px) {
  .site-header.extra-boxed,
  .site-content.extra-boxed,
  .site-footer.extra-boxed {
    max-width: 780px;
  }
}
@media (min-width: 992px) {
  .site-header.extra-boxed,
  .site-content.extra-boxed,
  .site-footer.extra-boxed {
    max-width: 1000px;
  }
}
@media (min-width: 1200px) {
  .site-header.extra-boxed,
  .site-content.extra-boxed,
  .site-footer.extra-boxed {
    max-width: 1200px;
  }
}

.entry-content .container .container {
  width: auto;
}

@media (min-width: 992px) {
  .sidebar-sidebar-content-wrapper,
  .sidebar-content-sidebar-wrapper,
  .content-sidebar-sidebar-wrapper {
    width: 80%;
  }

  .sidebar-content-sidebar-wrapper,
  .sidebar-sidebar-content-wrapper {
    float: right;
  }

  .content-sidebar-sidebar-wrapper {
    float: left;
  }

  .sidebar-content-wrapper #primary,
  .content-sidebar-wrapper #primary,
  .sidebar-sidebar-content-wrapper #primary,
  .sidebar-content-sidebar-wrapper #primary,
  .content-sidebar-sidebar-wrapper #primary {
    width: 75%;
  }

  .sidebar-content-wrapper #primary,
  .sidebar-sidebar-content-wrapper #primary {
    float: right;
  }

  .content-sidebar-wrapper #primary,
  .content-sidebar-sidebar-wrapper #primary,
  .sidebar-content-sidebar-wrapper #primary {
    float: left;
  }

  .sidebar-content-wrapper .widget-area,
  .sidebar-sidebar-content-wrapper .widget-area {
    float: left;
    width: 23%;
  }

  .content-sidebar-wrapper .widget-area,
  .content-sidebar-sidebar-wrapper .widget-area,
  .sidebar-content-sidebar-wrapper .widget-area {
    float: right;
    width: 23%;
  }

  .sidebar-sidebar-content-wrapper + .widget-area,
  .sidebar-content-sidebar-wrapper + .widget-area {
    float: left;
    width: 18%;
  }

  .content-sidebar-sidebar-wrapper + .widget-area {
    float: right;
    width: 18%;
  }

  .cherry-with-sidebar .content-area .container {
    width: 80%;
  }
}

/*
Description: The stylesheet with a responsive grid.
Author:      Cherry Team
Version:     1.0.0
*/
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}

.col-xs-1 {
  width: 8.33333%;
}

.col-xs-2 {
  width: 16.66667%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-4 {
  width: 33.33333%;
}

.col-xs-5 {
  width: 41.66667%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-7 {
  width: 58.33333%;
}

.col-xs-8 {
  
}/*width: 66.66667%;*/

.col-xs-9 {
  width: 75%;
}

.col-xs-10 {
  width: 83.33333%;
}

.col-xs-11 {
  width: 91.66667%;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 8.33333%;
}

.col-xs-pull-2 {
  right: 16.66667%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-4 {
  right: 33.33333%;
}

.col-xs-pull-5 {
  right: 41.66667%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-7 {
  right: 58.33333%;
}

.col-xs-pull-8 {
  right: 66.66667%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-10 {
  right: 83.33333%;
}

.col-xs-pull-11 {
  right: 91.66667%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 8.33333%;
}

.col-xs-push-2 {
  left: 16.66667%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-4 {
  left: 33.33333%;
}

.col-xs-push-5 {
  left: 41.66667%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-7 {
  left: 58.33333%;
}

.col-xs-push-8 {
  left: 66.66667%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-10 {
  left: 83.33333%;
}

.col-xs-push-11 {
  left: 91.66667%;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 8.33333%;
}

.col-xs-offset-2 {
  margin-left: 16.66667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.33333%;
}

.col-xs-offset-5 {
  margin-left: 41.66667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.33333%;
}

.col-xs-offset-8 {
  margin-left: 66.66667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.33333%;
}

.col-xs-offset-11 {
  margin-left: 91.66667%;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-2 {
    width: 16.66667%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.33333%;
  }

  .col-sm-5 {
    width: 41.66667%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.33333%;
  }

  .col-sm-8 {
    width: 66.66667%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.33333%;
  }

  .col-sm-11 {
    width: 91.66667%;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-pull-1 {
    right: 8.33333%;
  }

  .col-sm-pull-2 {
    right: 16.66667%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-4 {
    right: 33.33333%;
  }

  .col-sm-pull-5 {
    right: 41.66667%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-7 {
    right: 58.33333%;
  }

  .col-sm-pull-8 {
    right: 66.66667%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-10 {
    right: 83.33333%;
  }

  .col-sm-pull-11 {
    right: 91.66667%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-push-1 {
    left: 8.33333%;
  }

  .col-sm-push-2 {
    left: 16.66667%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-4 {
    left: 33.33333%;
  }

  .col-sm-push-5 {
    left: 41.66667%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-7 {
    left: 58.33333%;
  }

  .col-sm-push-8 {
    left: 66.66667%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-10 {
    left: 83.33333%;
  }

  .col-sm-push-11 {
    left: 91.66667%;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66667%;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-2 {
    width: 16.66667%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.33333%;
  }

  .col-md-5 {
    width: 41.66667%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.33333%;
  }

  .col-md-8 {
    width: 66.66667%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.33333%;
  }

  .col-md-11 {
    width: 91.66667%;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-pull-1 {
    right: 8.33333%;
  }

  .col-md-pull-2 {
    right: 16.66667%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-4 {
    right: 33.33333%;
  }

  .col-md-pull-5 {
    right: 41.66667%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-7 {
    right: 58.33333%;
  }

  .col-md-pull-8 {
    right: 66.66667%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-10 {
    right: 83.33333%;
  }

  .col-md-pull-11 {
    right: 91.66667%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-push-1 {
    left: 8.33333%;
  }

  .col-md-push-2 {
    left: 16.66667%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-4 {
    left: 33.33333%;
  }

  .col-md-push-5 {
    left: 41.66667%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-7 {
    left: 58.33333%;
  }

  .col-md-push-8 {
    left: 66.66667%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-10 {
    left: 83.33333%;
  }

  .col-md-push-11 {
    left: 91.66667%;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333%;
  }

  .col-md-offset-2 {
    margin-left: 16.66667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333%;
  }

  .col-md-offset-5 {
    margin-left: 41.66667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333%;
  }

  .col-md-offset-8 {
    margin-left: 66.66667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333%;
  }

  .col-md-offset-11 {
    margin-left: 91.66667%;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-2 {
    width: 16.66667%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.33333%;
  }

  .col-lg-5 {
    width: 41.66667%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.33333%;
  }

  .col-lg-8 {
    width: 66.66667%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.33333%;
  }

  .col-lg-11 {
    width: 91.66667%;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-pull-1 {
    right: 8.33333%;
  }

  .col-lg-pull-2 {
    right: 16.66667%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-4 {
    right: 33.33333%;
  }

  .col-lg-pull-5 {
    right: 41.66667%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-7 {
    right: 58.33333%;
  }

  .col-lg-pull-8 {
    right: 66.66667%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-10 {
    right: 83.33333%;
  }

  .col-lg-pull-11 {
    right: 91.66667%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-push-1 {
    left: 8.33333%;
  }

  .col-lg-push-2 {
    left: 16.66667%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-4 {
    left: 33.33333%;
  }

  .col-lg-push-5 {
    left: 41.66667%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-7 {
    left: 58.33333%;
  }

  .col-lg-push-8 {
    left: 66.66667%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-10 {
    left: 83.33333%;
  }

  .col-lg-push-11 {
    left: 91.66667%;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66667%;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }
}


.hide768{
    display: block;
  }

.show768{
  display: none;
}

@media screen and (max-width: 1200px){
  .container{
    width: 92% !important;
  }

  #menu-primary ul li{
    margin: 0 2px;
  }

  .proc_aukce .points_number .point{
    //width: 46%;
  }

  .hide768{
    display: none;
  }

  .show768{
    display: block;
  }

  .mobile_menu{
        position: absolute;
      background: #303739;
      width: 100%;
    left: 0;
    z-index: 9999;
    top: 153px;
  }

  .mobile_menu ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .mobile_menu a{
    color: #fff;
    display: block;
    text-align: center;
    padding: 6px;
    text-transform: uppercase;
  }

}

@media screen and (max-width: 1042px){
  .copy_line a{
    display: inline-block;
    margin-top: 20px;
  }

  .copy_line{
    text-align: center;
    height: auto;
  }

  .copy_line span{
    text-align: center;
    width: 100%;
  }

  .copy_line_newsletter .checkboxes{
    justify-content: center;
  }
}

@media screen and (max-width: 992px){
  .auction-left .custom_acc{
    display: block !important;
  }

  .auction-left .tab.navbar.affix-top{
    display: none;
  }

  .bg-box h4.border-bottom{
    display: block;
  }
}

@media screen and (max-width: 890px){
  .hp_boxes .box{
    width: 100%;
    margin: 2% 0;
    height: auto;
  }
}

@media screen and (max-width: 790px){
  .hp_block .hp_icons .icon{
    width: 100%;
    margin-right: 0;
    height: auto;
  }
}


@media screen and (max-width: 768px){
  .footer{
    text-align: center;
  }

  .faq_box{
    width: 100%;
    margin-right: 0;
  }

  .proc_aukce .points_number .point{
    width: 100%;
    margin: 0 0 30px;
  }

  .copy_line span{
    //display: none;
  }

  .copy_line_newsletter > span{
    display: block;
    margin-top: 0;
    margin-bottom: 15px;
  }

  .copy_line_newsletter{
    flex-direction: column;
  }

  .copy_line a{
  }

  .auction-right,.auction-left{
    padding: 0;
  }

  .single_aukce_container{
    width: 100% !important;
  }

  div.bg-prd-slider{
    padding: 0;
    margin-bottom: 0;
  }

  div.bg-prd-slider .flexslider{
    border: 0;
  }

  .single_aukce_container .title_cont_aukce{
    padding: 0 2%;
    //background-color: #fff;
  }

  .auction-right{
    margin-top: 0;
  }

  .border-bottom{
    border-top: 0;
    padding-top: 0;
  }

  .container_vypis_mobile{
    width: 100% !important;

  }

  .vypis_aukci .left-sidebar{
    padding-top: 0;
  }

  .vypis_aukci .sidebar-menu li.header{
    text-align: left;
  }

  .head-note{
    width: 100%;
    margin: 0;
  }

  .head-note h2, .vypis_aukci .shortBy-bg{
    padding: 0 2%;
  }

  .vypis_aukci .shortBy-bg{
    padding-bottom: 20px;
  }

  .akce_filtry{
    background-image: url("../img/filtr_arrow.png") !important;
    background-position: 95% 50% !important;
    background-color: #347ab8 !important;
    background-repeat: no-repeat !important;
  }

  .aukce_filtry_cont{
    display: none;
  }
}

@media screen and (max-width: 690px){

  .proc_aukce .points_number{
    width: 100%;
  }

  .proc_aukce .points_number .point .text{
    width: 60%;
  }

  .aukce_list .aukce{
    text-align: center;
  }

  .aukce_list .aukce .title{
    width: 100%;
    float: none;
    display: inline-block;
    margin: 6px 0;
  }

  .aukce_list .aukce .img{
    float: none;
    display: inline-block;
    margin: 0;
  }

  .aukce_list .aukce .right_cnt{
    float: none;

  }

  .aukce_list .aukce .date{
    float: none;
  }

  .aukce_list .aukce .btn{
    margin-left: 0;
  }

  .hp_block .testimonials .testimonial{
    width: 100%;
    float: none;
    margin: 2% 0 40px;
  }

  .hp_block .testimonials .testimonial .img{
    margin: auto auto 15px;
  }
}

@media screen and (max-width: 660px){
  .vypis_aukci .list-veiw .box .img{
    max-width: 100%;
    width: 22%;
  }

  .vypis_aukci .show768 .right_info{
    width: 78%;
  }

  .vypis_aukci .show768 .right_info p{
    width: 100%;
  }

  .vypis_aukci .show768 .right_info p.margined{
  margin-bottom: 10px;
}
.kontakt .wpcf7-submit{
  position: relative;
    top: 0;
}

.kontakt .wpcf7-form .required_info{
  //text-align: left;
}
}

@media screen and (max-width: 570px){
  .vypis_aukci .list-veiw .box .img{
    width: 28%;
    margin-right: 3%;
  }

  .vypis_aukci .show768 .right_info{
    width: 69%;
    float: left;
  }

  .vypis_aukci .shortBy-bg .title{
    float: none;
  }

  .vypis_aukci .shortBy-bg .select_right{
    float: none;
    display: none;
  }

  .proc_aukce .btn{
    padding: 12px 18px;
  }
}


@media screen and (max-width: 1200px){
  .filter_vypis .basic_filters label{
    display: block;
  }

  .filter_vypis .basic_filters select{
    margin-left: 0;
  }

  .filter_vypis .price_content{
    width: 100%;
    padding-right: 0;
  }

  .filter_vypis .label_price{
    width: 100%;
  }

  .vypis_aukci .list-veiw .box .img .ended_img span{
    margin-top: 20%;
    padding-top: 40%;
        background-size: 35% !important;
  }
}

@media screen and (max-width: 990px){
  .filter_vypis .col-custom{
    width: 50%;
    margin-bottom: 20px;
  }

  .filter_vypis .col-custom.longer{
    width: 45%;
  }

  .filter_vypis .col-custom.last{
    width: 50%;
    margin-top: 18px;
  }

  #vymera_slider{
    width: 98%;
    left: 1%;
  }

  .vypis_aukci .list-veiw .box .img .ended_img span{
    font-size: 0;
    height: 100%;
    padding: 0;
    margin: 0;
    background-position: 50% 50% !important;
  }
}

@media screen and (max-width: 540px){
  .filter_vypis .col-custom, .filter_vypis .col-custom.longer, .filter_vypis .col-custom.last{
    width: 100%;
    float: none;
    padding: 0;
  }

  .filter_vypis .basic_filters .input_labeled input{
    max-width: initial;
  }

  .filter_vypis .basic_filters select{
    width: 100%;
  }

  .filter_vypis .price_content .left_price, .filter_vypis .price_content .right_price{
    width: 100%;
    float: none;
    margin-bottom: 10px;
  }

  .filter_vypis .basic_filters .input_labeled input{
    width: 100%;
  }

  .input_labeled{
    width: 100%;
  }
}